import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Home } from "./components/Home/Home";
import { QueryClient, QueryClientProvider } from "react-query";
import { Layout } from "./components/Layout/Layout";
import { Video } from "./components/Video/Video";
import { Redirect } from "./components/Redirect/Redirect";
import { NotFound } from "./components/NotFound/NotFound";
import { AppContextProvider } from "./context/store";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AppContextProvider>
        <Home />
      </AppContextProvider>
    ),
  },

  {
    path: ":surah",
    element: (
      <AppContextProvider>
        <Layout />
      </AppContextProvider>
    ),
    children: [
      {
        path: "",
        element: (
            <Video />
        ),
      },
    ],
  },
  { path: "404", element: <NotFound /> },
  { path: "*", element: <NotFound /> },
]);

function App() {
	let query = new QueryClient();

	return (
		<QueryClientProvider client={query}>
			<RouterProvider router={router} />
		</QueryClientProvider>
	);
}

export default App;
