import { useEffect } from "react";
import { useAppContext } from "../../context/store";
import { MoonLoader } from "react-spinners";

export const PlayBtn = () => {
  const { isBtnPlaying, setIsBtnPlaying, setBtnLoader, btnLoader } =
    useAppContext();
  useEffect(() => {
		setBtnLoader(true);
		setTimeout(() => {
			setBtnLoader(false);
			console.log('settimeout');
		}, 100);
	}, []);

	return (
		<button
			onClick={() => {
				if (!btnLoader) {
					setIsBtnPlaying(!isBtnPlaying);
				}
			}}
			className='bg-p-dark transition-all duration-300 ring-2 ring-y-dark rounded-full size-10 lg:size-16 flex items-center justify-center absolute left-1/2 -translate-x-1/2 top-0 -translate-y-1/2'
		>
			{btnLoader ? (
				<MoonLoader
					color='#ffae00'
					size={30}
					speedMultiplier={0.5}
				/>
			) : (
				<>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className={`${
							isBtnPlaying ? 'w-0 h-0 opacity-0' : 'w-6 lg:w-9 opacity-100'
						} transition-all`}
						viewBox='0 0 48 48'
						id='play'
					>
						<path
							fill='#ffae00'
							d='M12 39c-.549 0-1.095-.15-1.578-.447A3.008 3.008 0 0 1 9 36V12c0-1.041.54-2.007 1.422-2.553a3.014 3.014 0 0 1 2.919-.132l24 12a3.003 3.003 0 0 1 0 5.37l-24 12c-.42.21-.885.315-1.341.315z'
						></path>
					</svg>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						className={`${
							isBtnPlaying ? 'w-7 lg:w-10 opacity-100' : 'w-0 h-0 opacity-0'
						} transition-all`}
						viewBox='0 0 24 24'
						id='pause'
					>
						<path
							fill='#ffae00'
							d='M8 5a2 2 0 0 0-2 2v10a2 2 0 1 0 4 0V7a2 2 0 0 0-2-2zm8 0a2 2 0 0 0-2 2v10a2 2 0 1 0 4 0V7a2 2 0 0 0-2-2z'
						></path>
					</svg>
				</>
			)}
		</button>
	);
};
