import LottieClient from '../LottieClient/LottieClient';
import koran from './koran.json';
import { motion } from 'framer-motion';
export default function Hero() {
	const handleScroll = () => {
		const el = document.getElementById('learn');
		el?.scrollIntoView({ behavior: 'smooth' });
	};
	return (
		<div className='container mx-auto h-screen-s flex items-center justify-center pt-20'>
			<section className='min-w-full flex  flex-col-reverse md:flex-row items-center justify-center gap-10 flex-1 h-full  '>
				<div className='flex  flex-col items-center md:w-1/2 '>
					<div className='column flex flex-col pb-5 items-center justify-center'>
						<h1 className='text-3xl max-w-sm md:max-w-md lg:max-w-3xl md:text-4xl  xl:text-5xl 2xl:text-6xl font-medium p-5 leading-relaxed md:leading-[4rem] xl:leading-[6rem] 2xl:leading-[7rem] text-center'>
							تعلم آيات القرآن الكريم بطريقة سهلة وممتعة
						</h1>
						<ul
							className={`social-icons flex items-end justify-end gap-3 lg:gap-5 `}
						>
							<motion.li
								initial={{ opacity: 0, y: '100%' }}
								viewport={{ once: true }}
								whileInView={{ opacity: 1, y: '0%' }}
								transition={{
									duration: '.5',
									delay: 0.3,
								}}
							>
								<a
									target='_blank'
									href={'https://www.instagram.com/surahxsura/'}
									className=' hover:text-[#ffae00] fill-current transition-all duration-300'
								>
									<svg
										className='fill-current w-8 lg:w-12'
										xmlns='http://www.w3.org/2000/svg'
										data-name='Layer 1'
										viewBox='0 0 24 24'
										id='instagram'
									>
										<path d='M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z'></path>
									</svg>
								</a>
							</motion.li>

							<motion.li
								initial={{ opacity: 0, y: '100%' }}
								viewport={{ once: true }}
								whileInView={{ opacity: 1, y: '0%' }}
								transition={{
									duration: '.5',
									delay: 0.7,
								}}
							>
								<a
									target='_blank'
									href={'https://www.facebook.com/surahxsura/'}
									className=' hover:text-[#ffae00] fill-current transition-all duration-300'
								>
									<svg
										className='fill-current w-8 lg:w-12'
										xmlns='http://www.w3.org/2000/svg'
										data-name='Layer 1'
										viewBox='0 0 24 24'
										id='facebook'
									>
										<path d='M15.12,5.32H17V2.14A26.11,26.11,0,0,0,14.26,2C11.54,2,9.68,3.66,9.68,6.7V9.32H6.61v3.56H9.68V22h3.68V12.88h3.06l.46-3.56H13.36V7.05C13.36,6,13.64,5.32,15.12,5.32Z'></path>
									</svg>
								</a>
							</motion.li>
							<motion.li
								initial={{ opacity: 0, y: '100%' }}
								viewport={{ once: true }}
								whileInView={{ opacity: 1, y: '0%' }}
								transition={{
									duration: '.5',
									delay: 0.9,
								}}
							>
								<a
									target='_blank'
									href={'https://www.tiktok.com/@surahxsurah'}
									className=' hover:text-[#ffae00] fill-current transition-all duration-300'
								>
									<svg
										className='fill-current w-7 lg:w-10'
										xmlns='http://www.w3.org/2000/svg'
										fill-rule='evenodd'
										clip-rule='evenodd'
										image-rendering='optimizeQuality'
										shape-rendering='geometricPrecision'
										text-rendering='geometricPrecision'
										viewBox='0 0 512 512'
										id='tiktok'
									>
										<path d='M353.97 0.43c0.03,7.81 2.31,120.68 120.76,127.72 0,32.55 0.04,56.15 0.04,87.21 -8.97,0.52 -77.98,-4.49 -120.93,-42.8l-0.13 169.78c1.63,117.84 -85.09,189.55 -198.44,164.78 -195.46,-58.47 -130.51,-348.37 65.75,-317.34 0,93.59 0.05,-0.03 0.05,93.59 -81.08,-11.93 -108.2,55.52 -86.65,103.81 19.6,43.97 100.33,53.5 128.49,-8.53 3.19,-12.14 4.78,-25.98 4.78,-41.52l0 -337.13 86.28 0.43z'></path>
									</svg>
								</a>
							</motion.li>

							<motion.li
								initial={{ opacity: 0, y: '100%' }}
								viewport={{ once: true }}
								whileInView={{ opacity: 1, y: '0%' }}
								transition={{
									duration: '.5',
									delay: 1.1,
								}}
							>
								<a
									target='_blank'
									href={'https://www.youtube.com/@surahxsurah'}
									className=' hover:text-[#ffae00] fill-current transition-all uration-300'
								>
									<svg
										className='fill-current w-8 lg:w-12'
										xmlns='http://www.w3.org/2000/svg'
										data-name='Layer 1'
										viewBox='0 0 24 24'
										id='youtube'
									>
										<path d='M23,9.71a8.5,8.5,0,0,0-.91-4.13,2.92,2.92,0,0,0-1.72-1A78.36,78.36,0,0,0,12,4.27a78.45,78.45,0,0,0-8.34.3,2.87,2.87,0,0,0-1.46.74c-.9.83-1,2.25-1.1,3.45a48.29,48.29,0,0,0,0,6.48,9.55,9.55,0,0,0,.3,2,3.14,3.14,0,0,0,.71,1.36,2.86,2.86,0,0,0,1.49.78,45.18,45.18,0,0,0,6.5.33c3.5.05,6.57,0,10.2-.28a2.88,2.88,0,0,0,1.53-.78,2.49,2.49,0,0,0,.61-1,10.58,10.58,0,0,0,.52-3.4C23,13.69,23,10.31,23,9.71ZM9.74,14.85V8.66l5.92,3.11C14,12.69,11.81,13.73,9.74,14.85Z'></path>
									</svg>
								</a>
							</motion.li>
							<motion.li
								initial={{ opacity: 0, y: '100%' }}
								viewport={{ once: true }}
								whileInView={{ opacity: 1, y: '0%' }}
								transition={{
									duration: '.5',
									delay: 1.3,
								}}
							>
								<a
									target='_blank'
									href={'https://www.snapchat.com/add/surahxsurah'}
									className=' hover:text-[#ffae00] fill-current transition-all uration-300'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 16 16'
										className='fill-current w-7 lg:w-10'
										id='snapchat'
									>
										<path d='M15.686 11.201c-2.139-.352-3.103-2.543-3.142-2.636l-.009-.018c-.109-.222-.135-.405-.075-.544.117-.274.565-.416.861-.51.086-.027.167-.053.234-.079.292-.115.5-.24.638-.381.164-.169.196-.331.194-.436-.005-.256-.201-.484-.514-.595a.93.93 0 0 0-.348-.066.775.775 0 0 0-.324.066c-.25.117-.474.181-.667.19a.612.612 0 0 1-.221-.048l.019-.309.003-.046c.069-1.083.154-2.43-.204-3.23a4.345 4.345 0 0 0-.979-1.401A4.094 4.094 0 0 0 9.981.386 4.627 4.627 0 0 0 8.154 0h-.04l-.275.003c-.355 0-1.066.05-1.829.386a4.071 4.071 0 0 0-1.167.771 4.359 4.359 0 0 0-.975 1.398c-.358.799-.273 2.146-.205 3.228v.002l.022.355a.64.64 0 0 1-.262.049c-.202 0-.442-.064-.713-.191a.64.64 0 0 0-.271-.056.958.958 0 0 0-.479.134c-.186.109-.306.262-.339.433-.021.112-.02.335.228.561.137.124.337.239.596.341.068.027.149.052.234.079.296.094.745.236.862.51.059.139.033.322-.076.544l-.008.018a5.295 5.295 0 0 1-.806 1.246 4.523 4.523 0 0 1-.972.858 3.577 3.577 0 0 1-1.365.531.373.373 0 0 0-.313.39c.003.055.016.11.039.163v.001c.077.179.254.33.542.463.352.163.878.3 1.565.407.035.066.071.231.096.344.026.12.053.244.092.375.042.142.15.312.429.312.106 0 .227-.024.368-.051.206-.04.488-.095.839-.095.195 0 .397.017.6.051.392.065.73.303 1.121.579.572.404 1.22.861 2.21.861l.081-.003c.032.002.073.003.115.003.99 0 1.638-.457 2.21-.86l.001-.001c.391-.275.729-.513 1.121-.578.203-.034.405-.051.6-.051.336 0 .602.043.839.089.155.03.275.045.368.045h.018a.405.405 0 0 0 .411-.307 5.3 5.3 0 0 0 .092-.372c.023-.106.061-.276.095-.342.687-.108 1.213-.244 1.565-.407.287-.133.464-.284.541-.462a.475.475 0 0 0 .04-.165.372.372 0 0 0-.313-.388z'></path>
									</svg>
								</a>
							</motion.li>
						</ul>
						<a
							href='mailto:info@surahxsurah.com'
							className='text-xl hover:text-[#ffae00] transition-all duration-300 py-5'
						>
							info@surahxsurah.com
						</a>
					</div>
					<button
						onClick={handleScroll}
						className=' w-40 my-5 flex items-center justify-center gap-5 title text-xl font-medium bg-p-dark text-p-lighter hover:shadow-md hover:shadow-p-dark transition-all p-3  rounded-lg'
					>
						<span className='text-center w-fit font'>بسم الله</span>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className={`transition-all rounded-3xl text-center w-7 text-xs lg:text-lg p-1 animate-bounce`}
							data-name='Layer 1'
							viewBox='0 0 32 32'
							id='down-arrow'
						>
							<path
								fill='#fff'
								d='M16 22a2 2 0 0 1-1.41-.59l-10-10a2 2 0 0 1 2.82-2.82L16 17.17l8.59-8.58a2 2 0 0 1 2.82 2.82l-10 10A2 2 0 0 1 16 22Z'
							></path>
						</svg>
					</button>
				</div>

				<div className='w-72 md:w-2/5 flex items-center justify-center'>
					<LottieClient
						animation={koran}
						autoPlay={true}
					/>
				</div>
			</section>
		</div>
	);
}
