
import Lottie from "lottie-react";
import { useRef } from "react";
export default function LottieClient({ animation, autoPlay, slow }) {
	const lottiePlayer = useRef();
	const displayLastFrame = () => {
		if (!autoPlay) {
			lottiePlayer.current?.goToAndStop(
				lottiePlayer.current?.getDuration(true) / 2,
				true
			);
		}
		if (slow) {
			lottiePlayer.current?.setSpeed(0.35);
		}
	};
	const play = () => {
		lottiePlayer.current?.play();
	};

	return (
		<Lottie
			lottieRef={lottiePlayer}
			animationData={animation}
			autoplay={autoPlay}
			onDOMLoaded={displayLastFrame}
			onMouseMove={play}
			onTouchMove={play}
			onTouchStart={play}
			onMouseLeave={displayLastFrame}
			onTouchEnd={displayLastFrame}
			style={{ height: '100%' }}
		/>
	);
}
