import lotties from '../../lotties.json';
import LottieClient from '../LottieClient/LottieClient';

export default function SurahCard({ number, name, revelationType, ayahs }) {
	let lottie = lotties[number.toString()].lottie;
	return (
		<div className='shadow-sm hover:shadow-lg hover:shadow-p-dark transition-shadow rounded-md  flex items-center justify-between w-full overflow-hidden bg-p-dark text-p-lighter p-2'>
			<div className='level-details w-1/2  flex flex-col items-start justify-between  p-3'>
				<h2 className='text-lg text-y-dark mb-3 font-semibold'>{name} </h2>
				<div className='details '>
					<h4>{revelationType === 'Meccan' ? 'مكية' : 'مدنية'}</h4>
					<h4> ترتيبها: {number}</h4>
					<h4> آياتها: {ayahs}</h4>
				</div>
			</div>
			<div className='lottie w-1/2 bg-p-lighter rounded-lg overflow-hidden'>
				<LottieClient
					animation={lottie}
					autoPlay={false}
					slow={true}
				/>
			</div>
		</div>
	);
}
