import { createContext, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";

const AppContext = createContext({
	//   playMode: "s",
	//   setPlayMode: () => "s",
	//   ayahReplayTimes: 1,
	//   setAyahReplayTimes: () => 1,
	//   surahReplayTimes: 1,
	//   setSurahReplayTimes: () => 1,
	selectedAudio: 'ar.hudhaify',
	setSelectedAudio: () => 'ar.hudhaify',
	currReciter: [],
	setCurrReciter: () => [],
	currTafsir: [],
	setCurrTafsir: () => [],
	surahTimesPlayed: 1,
	setSurahTimesPlayed: () => 1,
	ayahTimesPlayed: 1,
	setAyahTimesPlayed: () => 1,
	isMenuOpened: false,
	setIsMenuOpened: () => false,
	isAsideOpened: false,
	setIsAsideOpened: () => false,
	isRecitersMenuOpened: false,
	setIsRecitersMenuOpened: () => false,
	isTafsirMenuOpened: false,
	setIsTafsirMenuOpened: () => false,
	isAyahPlaying: false,
	setIsAyahPlaying: () => false,
	isTafsirVisible: false,
	setIsTafsirVisible: () => false,
	isSurahPlaying: false,
	setIsSurahPlaying: () => false,
	rSurah: false,
	setRSurah: () => false,
	selectedTafasir: [''],
	setSelectedTafasir: () => [''],
	selectedTarajim: [''],
	setSelectedTarajim: () => [''],
	isAudioPlaying: false,
	setIsAudioPlaying: () => false,
	btnLoader: true,
	setBtnLoader: () => true,
	isBtnPlaying: false,
	setIsBtnPlaying: () => false,
	searchParams: { reciter: 'ar.hudhaify', tafsir: 'ar.muyassar' },
	setSearchParams: () => [
		{
			reciter: 'ar.hudhaify',
			tafsir: 'ar.muyassar',
		},
	],
});

export const AppContextProvider = ({ children }) => {
	const [isTafsirVisible, setIsTafsirVisible] = useState(false);
	const [isMenuOpened, setIsMenuOpened] = useState(false);
	const [isAsideOpened, setIsAsideOpened] = useState(false);
	const [isRecitersMenuOpened, setIsRecitersMenuOpened] = useState(false);
	const [isTafsirMenuOpened, setIsTafsirMenuOpened] = useState(false);
	const [rSurah, setRSurah] = useState(false);
	const [isSurahPlaying, setIsSurahPlaying] = useState(false);
	const [isAyahPlaying, setIsAyahPlaying] = useState(false);
	const [selectedTafasir, setSelectedTafasir] = useState(null);
	const [selectedTarajim, setSelectedTarajim] = useState(null);
	const [isAudioPlaying, setIsAudioPlaying] = useState(false);
	const [isBtnPlaying, setIsBtnPlaying] = useState(false);
	const [btnLoader, setBtnLoader] = useState(true);
	//   const [playMode, setPlayMode] = useState("s");
	//   const [ayahReplayTimes, setAyahReplayTimes] = useState(1);
	//   const [surahReplayTimes, setSurahReplayTimes] = useState(1);
	const [surahTimesPlayed, setSurahTimesPlayed] = useState(1);
	const [ayahTimesPlayed, setAyahTimesPlayed] = useState(1);
	const [currReciter, setCurrReciter] = useState([]);
	const [currTafsir, setCurrTafsir] = useState([]);
	const [selectedAudio, setSelectedAudio] = useState('');
	const [searchParams, setSearchParams] = useSearchParams({
		currentAyah: '',
		reciter: '',
		// playMode: '',
		// ayahReplayTimes: '',
		// surahReplayTimes: '',
		// tafsir: '',
	});
	// const mode = searchParams.get('playMode');
	// const ayahR = searchParams.get('ayahReplayTimes');
	// const surahR = searchParams.get('surahReplayTimes');
	// const tafsir = searchParams.get('tafsir');
	const currentAyah = searchParams.get('currentAyah');
	const reciter = searchParams.get('reciter');

	return (
		<AppContext.Provider
			value={{
				// playMode,
				// setPlayMode,
				// ayahReplayTimes,
				// setAyahReplayTimes,
				// surahReplayTimes,
				// setSurahReplayTimes,
				rSurah,
				setRSurah,
				ayahTimesPlayed,
				setAyahTimesPlayed,
				surahTimesPlayed,
				setSurahTimesPlayed,
				isAudioPlaying,
				setIsAudioPlaying,
				isTafsirVisible,
				setIsTafsirVisible,
				isBtnPlaying,
				setIsBtnPlaying,
				btnLoader,
				setBtnLoader,
				currReciter,
				setCurrReciter,
				currTafsir,
				setCurrTafsir,
				selectedAudio,
				setSelectedAudio,
				isMenuOpened,
				setIsMenuOpened,
				isAsideOpened,
				setIsAsideOpened,
				selectedTafasir,
				setSelectedTafasir,
				selectedTarajim,
				setSelectedTarajim,
				isRecitersMenuOpened,
				setIsRecitersMenuOpened,
				isTafsirMenuOpened,
				setIsTafsirMenuOpened,
				searchParams,
				setSearchParams,
				// mode,
				// ayahR,
				// surahR,
				// tafsir,
				currentAyah,
				reciter,
				isSurahPlaying,
				setIsSurahPlaying,
				isAyahPlaying,
				setIsAyahPlaying,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export const useAppContext = () => useContext(AppContext);
