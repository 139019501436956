import axios from 'axios';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../../context/store';
import { PlayBtn } from '../PlayBtn/PlayBtn';

export const AyahUthmani = () => {
	const { surah } = useParams();
	const { currentAyah } = useAppContext();

	const getAyahUthmani = () => {
		return axios.get(
			`https://api.alquran.cloud/v1/ayah/${surah}:${currentAyah}/quran-uthmani`
		);
	};
	const { data, refetch } = useQuery('ayahUthmani', getAyahUthmani);
	useEffect(() => {
		refetch();
	}, [surah, currentAyah]);

	return (
		<p className=' rounded-xl flex-1 p-3 lg:p-6 text-center bg-p-dark text-y-light text-lg lg:text-2xl _amiri leading-loose relative pt-8 lg:pt-12'>
			<PlayBtn />
			{surah === '1' && currentAyah === '1'
				? data?.data.data.text
				: data?.data.data.text
						.replace('بِسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ', '')
						.replace('بِّسْمِ ٱللَّهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ', '')}
		</p>
	);
};
