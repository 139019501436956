import { Link, useParams } from "react-router-dom";
import surahs from "../../surahs.json";
import { useState } from "react";
import { toArabic } from "arabic-digits";
import { useAppContext } from "../../context/store";
export const CurrSurah = () => {
  const { surah } = useParams();
  const {
		mode,
		ayahR,
		surahR,
		reciter,
		tafsir,
		setIsBtnPlaying,
		setAyahTimesPlayed,
		setBtnLoader,
	} = useAppContext();

  const [isSurahMenuOpened, setIsSurahMenuOpened] = useState(false);
  
  let s = surahs.find((s) => s.order === surah);
  const handleChangeSurah = () => {
		setIsBtnPlaying(false);
		setAyahTimesPlayed(1);
		setBtnLoader(true);
		setTimeout(() => {
			setBtnLoader(false);
			console.log('settimeout');
		}, 100);
	};

	return (
		<div className='surah-title text-p-lighter  w-full flex items-center justify-center gap-5'>
			<div
				onClick={(e) => {
					if (e.target.id === 'surahOverlay') {
						setIsSurahMenuOpened(false);
					}
				}}
				className={`${
					isSurahMenuOpened ? '' : 'hidden'
				}  absolute top-0 left-0 bottom-0 right-0  z-[40]`}
				id='surahOverlay'
			></div>
			<h2
				onClick={() => {
					setIsSurahMenuOpened(!isSurahMenuOpened);
				}}
				className={`relative transition-all hover:bg-y-dark cursor-pointer ${
					isSurahMenuOpened ? 'bg-y-dark' : 'bg-p-dark'
				} rounded-3xl text-sm lg:text-xl text-center py-2 px-5 w-52 flex items-center justify-center gap-1`}
			>
				<div className='flex items-center justify-between w-full z-50'>
					<span>{toArabic(s.num) + ' - '}</span>
					<span className='flex-1 '>سورة {s.name}</span>
				</div>
				<div
					id='surahMenu'
					className={`${
						isSurahMenuOpened ? 'h-60' : 'h-0 opacity-0 scale-0'
					} transition-all duration-300 absolute z-50 bg-p-dark rounded-3xl  top-[110%] flex flex-col p-1  gap-1 overflow-y-scroll no-scrollbar`}
				>
					{surahs
						.filter((s) => s.order == 1 || s.order > 109)
						.map((s) => (
							<Link
								onClick={() => {
									handleChangeSurah();
								}}
								to={`/${s.order}?currentAyah=1&reciter=${reciter}`}
								key={s.order}
								className={`${
									s.order === surah ? 'bg-y-dark' : ''
								} py-2 px-5 hover:bg-y-dark transition-all rounded-3xl text-center w-52 flex items-center justify-between`}
							>
								<span>{toArabic(s.num) + ' - '}</span>
								<span className='flex-1 '>سورة {s.name}</span>
							</Link>
						))}
				</div>
			</h2>
		</div>
	);
};
