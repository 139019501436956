import { Link } from "react-router-dom";
import LoadingLottie from "../LoadingLottie/LoadingLottie";

export const NotFound = () => {
	return (
    <section className="h-screen-s flex flex-col items-center justify-center">
      <Link to={"/"} className="text-center text-6xl mb-5 font-bold">
        سورة x صورة
      </Link>
      <LoadingLottie />
      <div className="not-found">
        <h2 className="text-3xl">لا وجود لهذه الصفحة</h2>
      </div>
    </section>
  );
};
