import { AyahUthmani } from '../AyahUthmani/AyahUthmani';
import { PlayBtn } from '../PlayBtn/PlayBtn';
import { MainLottieAudio } from "../MainLottieAudio/MainLottieAudio";
// import { AyahTafsir } from './../AyahTafsir/AyahTafsir';
import { useAppContext } from '../../context/store';

export const Video = () => {
	const { isTafsirVisible } = useAppContext();

	return (
		<section className=' flex flex-col justify-center items-center h-full lg:px-5 gap-2 '>
			<div className='lottie overflow-hidden rounded-lg my-auto lg:w-1/2'>
				<MainLottieAudio />
			</div>
			<div className='flex items-center justify-center gap-2 w-full relative'>
				{/* <PlayBtn /> */}
				<AyahUthmani />
				<div
					className={`${
						isTafsirVisible ? 'scale-y-100' : 'scale-y-0'
					}  max-h-24  overflow-y-auto no-scrollbar  rounded-xl transition-all absolute  bottom-[120%] `}
				>
					{/* <AyahTafsir /> */}
				</div>
			</div>
		</section>
	);
};
