import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../context/store";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import lotties from "../../lotties.json";
import { PuffLoader } from "react-spinners";

export const MainLottieAudio = () => {
	// &&&&&&&&&&&&&& start Params &&&&&&&&&&&&&&
	const { surah } = useParams();
	// &&&&&&&&&&&&&& end Params &&&&&&&&&&&&&&
	const [ayahFinished, setAyahFinished] = useState(false);
	// &&&&&&&&&&&&&& start AppContext &&&&&&&&&&&&&&
	const {
		ayahTimesPlayed,
		setAyahTimesPlayed,
		surahTimesPlayed,
		setSurahTimesPlayed,
		isBtnPlaying,
		setIsBtnPlaying,
		// mode,
		// ayahR,
		// surahR,
		reciter,
		currentAyah,
		selectedAudio,
		setSelectedAudio,
		setSearchParams,
		btnLoader,
	} = useAppContext();

	// &&&&&&&&&&&&&& end AppContext &&&&&&&&&&&&&&
	// &&&&&&&&&&&&&& start active lottie &&&&&&&&&&&&&&
	// let lottie = lotties[surah][currentAyah];

	// &&&&&&&&&&&&&& end active lottie &&&&&&&&&&&&&&
	// useEffect(() => {
	// 	if (mode !== 's' && mode !== 'a') {
	// 		setSearchParams((prev) => {
	// 			prev.set('playMode', 's');
	// 			return prev;
	// 		});
	// 	}
	// 	if (ayahR !== '1' && ayahR !== '3' && ayahR !== '7') {
	// 		setSearchParams((prev) => {
	// 			prev.set('ayahReplayTimes', '1');
	// 			return prev;
	// 		});
	// 	}
	// 	if (surahR !== '1' && surahR !== '3' && surahR !== '7') {
	// 		setSearchParams((prev) => {
	// 			prev.set('surahReplayTimes', '1');
	// 			return prev;
	// 		});
	// 	}
	// }, [mode, ayahR, surahR]);

	// &&&&&&&&&&&&&& start palyPause lottie || audio &&&&&&&&&&&&&&

	const playLottie = () => {
		lottieRef.current?.play();
	};
	const playAudio = () => {
		audioRef.current?.play();
	};
	const pauseLottie = () => {
		lottieRef.current?.pause();
	};
	const pauseAudio = () => {
		audioRef.current?.pause();
	};

	const playPause = () => {
		setNewSpeed().then(() => {
			if (isBtnPlaying && lottieRef.current) {
				playAudio();
				playLottie();
				console.log('setNewSpeed().then play()');
			} else {
				console.log('setNewSpeed().then Pause()');
				pauseAudio();
				pauseLottie();
			}
		});
	};

	// &&&&&&&&&&&&&& end palyPause lottie || audio &&&&&&&&&&&&&&

	// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&  start lottie &&&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

	// &&&&&&&&&&&&&& start display last Frame &&&&&&&&&&&&&&

	const lottieRef = useRef();
	const displayLastFrame = () => {
		lottieRef.current?.goToAndStop(
			lottieRef.current?.getDuration(true) - 1,
			true
		);
	};

	const handleDomLoad = () => {
		if (!isBtnPlaying) {
			displayLastFrame();
			console.log('displayLastFrame() ');
		}
	};

	// &&&&&&&&&&&&&& end display last Frame &&&&&&&&&&&&&&

	const setNewSpeed = () => {
		return new Promise((callback) => {
			lottieRef.current?.setSpeed(
				lottieRef.current?.getDuration(false) / audioRef.current?.duration
			);
			callback();
		});
	};

	// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&  end lottie &&&&&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

	// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&  start audio &&&&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

	// &&&&&&&&&&&&&&&&&&&&  start Audio API &&&&&&&&&&&&&&&&&&
	const getAudio = () => {
		return axios.get(`https://api.alquran.cloud/v1/surah/${surah}/${reciter}`);
	};
	const { data, refetch, isLoading } = useQuery('getAudio', getAudio);
	setSelectedAudio(data?.data.data.ayahs);

	// &&&&&&&&&&&&&&&&&&&&  end Audio API &&&&&&&&&&&&&&&&&&&&
	const audioRef = useRef();

	useEffect(() => {
		refetch();
		console.log('useEffect [reciter,surah] refetch() ');
	}, [reciter, surah]);
	useEffect(() => {
		setIsBtnPlaying(false);
		displayLastFrame();
		console.log(
			'useEffect [reciter] setIsBtnPlaying(false);displayLastFrame();'
		);
	}, [reciter]);

	useEffect(() => {
		playPause();
		console.log('useEffect [isBtnPlaying] playPause();');
	}, [isBtnPlaying]);
	// useEffect(() => {
	// 	if (mode === 's') {
	// 		handleVideo();
	// 		console.log('handle video');
	// 	}
	// }, [currentAyah]);

	// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&  end audio &&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

	// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&& start handle Video &&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

	// &&&&&&&&&&&&&&&&&&&&  start navigateToNextAyah &&&&&&&&&&&&&&&&&
	const navigateToNextAyah = () => {
		return new Promise((callback) => {
			if (
				Number(currentAyah) > 0 &&
				Number(currentAyah) < selectedAudio.length
			) {
				console.log(currentAyah, Number(currentAyah), selectedAudio.length);
				setSearchParams((prev) => {
					prev.set('currentAyah', Number(currentAyah) + 1);
					return prev;
				});
				console.log('navigateToNextAyah true ');
			}
			callback();
		});
	};
	// &&&&&&&&&&&&&&&&&&&&  end navigateToNextAyah &&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&  start navigateToFirstAyah &&&&&&&&&&&&&&&&&

	const navigateToFirstAyah = () => {
		return new Promise((callback) => {
			setSearchParams((prev) => {
				prev.set('currentAyah', '1');
				return prev;
			});
			console.log('navigateToFirstAyah');
			callback();
		});
	};

	// &&&&&&&&&&&&&&&&&&&&  end navigateToFirstAyah &&&&&&&&&&&&&&&&&

	// useEffect(() => {
	// 	if (mode === 's' && isBtnPlaying) {
	// 		checkSurahReplay();
	// 	}
	// }, [ayahFinished]);

	// &&&&&&&&&&&&&&&&&&&&  start check Surah Replay &&&&&&&&&&&&&&&&&
	// const checkSurahReplay = () => {
	// 	console.log('checkSurahReplay ayahFinished: ' + currentAyah);

	// 	navigateToNextAyah().then(() => {
	// 		if (
	// 			Number(currentAyah) > 0 &&
	// 			Number(currentAyah) < selectedAudio.length
	// 		) {
	// 			console.log('hi');
	// 			setTimeout(() => {
	// 				playPause();
	// 			}, 100);
	// 		} else if (Number(currentAyah) === selectedAudio.length) {
	// 			if (Number(surahTimesPlayed) < Number(surahR)) {
	// 				navigateToFirstAyah().then(() => {
	// 					console.log('navigated to first ayah');
	// 					setTimeout(() => {
	// 						playPause();
	// 						console.log('first ayah > then PlayPause');
	// 					}, 100);
	// 					setSurahTimesPlayed(surahTimesPlayed + 1);
	// 					console.log(
	// 						'surahTimesPlayed < Number(surahR)' +
	// 							' surahTimesPlayed' +
	// 							surahTimesPlayed +
	// 							' surahReplayTimes' +
	// 							surahR
	// 					);
	// 				});
	// 			} else {
	// 				setSurahTimesPlayed(1);
	// 				navigateToFirstAyah().then(() => {
	// 					console.log(
	// 						'surahTimesPlayed false' +
	// 							' surahTimesPlayed: ' +
	// 							surahTimesPlayed +
	// 							' surahReplayTimes: ' +
	// 							surahR
	// 					);
	// 					setIsBtnPlaying(false);
	// 				});
	// 				// setIsBtnPlaying(false)
	// 				// displayLastFrame();
	// 			}
	// 		}
	// 	});
	// };
	// &&&&&&&&&&&&&&&&&&&&  end check Surah Replay &&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&  start check Ayah Replay &&&&&&&&&&&&&&&&&
	const checkAyahReplay = () => {
		// if (ayahTimesPlayed < Number(ayahR)) {
		//   console.log("ayahTimesPlayed < Number(ayahR)");
		//   playPause();
		//   setAyahTimesPlayed(ayahTimesPlayed + 1);
		// } else {
		//   if (mode === "a") {
		displayLastFrame();
		setIsBtnPlaying(false);
		console.log('displayLastFrame()');
		// }
		setAyahTimesPlayed(1);
		setAyahFinished(!ayahFinished);
		// 	console.log('if (mode === a) ');
		// // } else if (mode === 's') {
		// 	setAyahTimesPlayed(1);
		// 	displayLastFrame();
		// 	setIsBtnPlaying(false);
		// 	console.log('else if (mode === s) ');
		// 	checkSurahReplay();
		// }
		// }
	};
	// &&&&&&&&&&&&&&&&&&&&  end check Ayah Replay &&&&&&&&&&&&&&&&&

	const handleVideo = () => {
		console.log('handleVideo ');
		if (isBtnPlaying) {
			console.log('handleVideo (isBtnPlaying) ');
			checkAyahReplay();
		}
	};

	// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&  end handle Video &&&&&&&&&&&&&&&&&
	// &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

	if (!selectedAudio || isLoading || btnLoader) {
		return <PuffLoader color='#f7b243' />;
	}
	return (
		<>
			<Lottie
				lottieRef={lottieRef}
				animationData={lotties[surah][currentAyah]}
				autoplay={false}
				onDOMLoaded={handleDomLoad}
				style={{ height: '100%' }}
			/>
			<audio
				ref={audioRef}
				src={selectedAudio[Number(currentAyah) - 1]?.audio}
				onEnded={handleVideo}
			></audio>
		</>
	);
};
