import { toArabic } from "arabic-digits";
import axios from "axios";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { useAppContext } from "../../context/store";

export const AyatNav = () => {
  const { mode, ayahR, surahR, reciter,currentAyah,setSearchParams, setIsBtnPlaying, setAyahTimesPlayed,setBtnLoader } =
    useAppContext();
  const { surah} = useParams();
  const getSurahDetails = () => {
    return axios.get(`https://api.alquran.cloud/v1/surah/${surah}`);
  };

  const { data: surahDetails, refetch } = useQuery(
    "surahDetails",
    getSurahDetails
  );
  useEffect(() => {
    refetch();
  }, [surah, currentAyah]);

  const changeCurrentAyah =(a)=>{
    setSearchParams((prev) => {
      prev.set("currentAyah", a.numberInSurah);
      return prev;
    });
  }

  return (
    <div className="ayat-num w-fit mx-auto rounded-3xl p-1 flex flex-wrap items-center justify-center gap-1 my-2 bg-p-dark text-p-lighter">
      {surahDetails?.data.data.ayahs.map((a) => (
        <button
          onClick={() => {
            changeCurrentAyah(a);
            setIsBtnPlaying(false);
            setAyahTimesPlayed(1);
            setBtnLoader(true);
						setTimeout(() => {
							setBtnLoader(false);
							console.log('settimeout');
						}, 100);
          }}
          key={a.numberInSurah}
          className={`hover:bg-y-dark cursor-pointer transition-all rounded-3xl text-center size-7 text-md p-1 ${
            a.numberInSurah === Number(currentAyah) ? " bg-y-dark" : ""
          }`}
        >
          {toArabic(a.numberInSurah)}
        </button>
      ))}
    </div>
  );
};
