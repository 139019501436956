import { Link, Outlet } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Aside } from '../Aside/Aside';
import { AyatNav } from './../AyatNav/AyatNav';
import { CurrSurah } from '../CurrSurah/CurrSurah';
import { RecitersMenu } from '../RecitersMenu/RecitersMenu';
import { TafsirMenu } from '../TafsirMenu/TafsirMenu';
import { useAppContext } from '../../context/store';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useState } from 'react';
export const Layout = () => {
	const {
		isRecitersMenuOpened,
		setIsRecitersMenuOpened,
		setCurrReciter,
		currReciter,
		reciter,
	} = useAppContext();
	// &&&&&&&&&&&&&&&&&&&&  start reciters API &&&&&&&&&&&&&&&&&&&&
	const getReciters = () => {
		return axios.get(`https://api.alquran.cloud/v1/edition/format/audio`);
	};

	const { data } = useQuery('reciters', getReciters);
	setCurrReciter(data?.data.data);
	// &&&&&&&&&&&&&&&&&&&&  end reciters API &&&&&&&&&&&&&&&&&&&&

	const [isBetaOn, setIsBetaOn] = useState(false);
	return (
		<>
			<div
				id='betalLayout'
				onClick={(e) => {
					if (e.target.id === 'betalLayout') {
						setIsBetaOn(false);
					}
				}}
				className={`${
					isBetaOn ? ' flex' : 'hidden'
				}  absolute top-0 left-0 bottom-0 right-0 bg-black/25 z-[99] justify-center items-center p-10 transition-all`}
			>
				<beta
					id='beta'
					className=' bg-white rounded-xl'
				>
					<section className='min-w-full flex  flex-col-reverse md:flex-row items-center justify-center flex-1 h-full  '>
						<div className='flex  flex-col items-center md:w-1/3 '>
							<div className='column flex flex-col p-10 gap-5 lg:gap-8 items-center justify-center rounded-2xl'>
								<div className='beta flex flex-col gap-5 items-center justify-center '>
									<h2 className='text-2xl'>الموقع قيد التطوير</h2>
									<p>نحن نعمل حاليًا على تحسين تجربتكم!</p>
									<p>لأي استفسارات أو اقتراحات، يسعدنا استقبال رسائلكم عبر:</p>
									<a
										href='mailto:info@surahxsurah.com'
										className='text-lg hover:text-[#ffae00] transition-all duration-300'
									>
										info@surahxsurah.com
									</a>
								</div>
								<ul
									className={`social-icons flex items-end justify-end gap-3 lg:gap-5 `}
								>
									<motion.li
										initial={{ opacity: 0, y: '100%' }}
										viewport={{ once: true }}
										whileInView={{ opacity: 1, y: '0%' }}
										transition={{
											duration: '.5',
											delay: 0.3,
										}}
									>
										<a
											target='_blank'
											href={'https://www.instagram.com/surahxsura/'}
											className=' hover:text-[#ffae00] fill-current transition-all duration-300'
										>
											<svg
												className='fill-current w-8 lg:w-12'
												xmlns='http://www.w3.org/2000/svg'
												data-name='Layer 1'
												viewBox='0 0 24 24'
												id='instagram'
											>
												<path d='M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z'></path>
											</svg>
										</a>
									</motion.li>

									<motion.li
										initial={{ opacity: 0, y: '100%' }}
										viewport={{ once: true }}
										whileInView={{ opacity: 1, y: '0%' }}
										transition={{
											duration: '.5',
											delay: 0.7,
										}}
									>
										<a
											target='_blank'
											href={'https://www.facebook.com/surahxsura/'}
											className=' hover:text-[#ffae00] fill-current transition-all duration-300'
										>
											<svg
												className='fill-current w-8 lg:w-12'
												xmlns='http://www.w3.org/2000/svg'
												data-name='Layer 1'
												viewBox='0 0 24 24'
												id='facebook'
											>
												<path d='M15.12,5.32H17V2.14A26.11,26.11,0,0,0,14.26,2C11.54,2,9.68,3.66,9.68,6.7V9.32H6.61v3.56H9.68V22h3.68V12.88h3.06l.46-3.56H13.36V7.05C13.36,6,13.64,5.32,15.12,5.32Z'></path>
											</svg>
										</a>
									</motion.li>
									<motion.li
										initial={{ opacity: 0, y: '100%' }}
										viewport={{ once: true }}
										whileInView={{ opacity: 1, y: '0%' }}
										transition={{
											duration: '.5',
											delay: 0.9,
										}}
									>
										<a
											target='_blank'
											href={'https://www.tiktok.com/@surahxsurah'}
											className=' hover:text-[#ffae00] fill-current transition-all duration-300'
										>
											<svg
												className='fill-current w-7 lg:w-10'
												xmlns='http://www.w3.org/2000/svg'
												fill-rule='evenodd'
												clip-rule='evenodd'
												image-rendering='optimizeQuality'
												shape-rendering='geometricPrecision'
												text-rendering='geometricPrecision'
												viewBox='0 0 512 512'
												id='tiktok'
											>
												<path d='M353.97 0.43c0.03,7.81 2.31,120.68 120.76,127.72 0,32.55 0.04,56.15 0.04,87.21 -8.97,0.52 -77.98,-4.49 -120.93,-42.8l-0.13 169.78c1.63,117.84 -85.09,189.55 -198.44,164.78 -195.46,-58.47 -130.51,-348.37 65.75,-317.34 0,93.59 0.05,-0.03 0.05,93.59 -81.08,-11.93 -108.2,55.52 -86.65,103.81 19.6,43.97 100.33,53.5 128.49,-8.53 3.19,-12.14 4.78,-25.98 4.78,-41.52l0 -337.13 86.28 0.43z'></path>
											</svg>
										</a>
									</motion.li>

									<motion.li
										initial={{ opacity: 0, y: '100%' }}
										viewport={{ once: true }}
										whileInView={{ opacity: 1, y: '0%' }}
										transition={{
											duration: '.5',
											delay: 1.1,
										}}
									>
										<a
											target='_blank'
											href={'https://www.youtube.com/@surahxsurah'}
											className=' hover:text-[#ffae00] fill-current transition-all uration-300'
										>
											<svg
												className='fill-current w-8 lg:w-12'
												xmlns='http://www.w3.org/2000/svg'
												data-name='Layer 1'
												viewBox='0 0 24 24'
												id='youtube'
											>
												<path d='M23,9.71a8.5,8.5,0,0,0-.91-4.13,2.92,2.92,0,0,0-1.72-1A78.36,78.36,0,0,0,12,4.27a78.45,78.45,0,0,0-8.34.3,2.87,2.87,0,0,0-1.46.74c-.9.83-1,2.25-1.1,3.45a48.29,48.29,0,0,0,0,6.48,9.55,9.55,0,0,0,.3,2,3.14,3.14,0,0,0,.71,1.36,2.86,2.86,0,0,0,1.49.78,45.18,45.18,0,0,0,6.5.33c3.5.05,6.57,0,10.2-.28a2.88,2.88,0,0,0,1.53-.78,2.49,2.49,0,0,0,.61-1,10.58,10.58,0,0,0,.52-3.4C23,13.69,23,10.31,23,9.71ZM9.74,14.85V8.66l5.92,3.11C14,12.69,11.81,13.73,9.74,14.85Z'></path>
											</svg>
										</a>
									</motion.li>
									<motion.li
										initial={{ opacity: 0, y: '100%' }}
										viewport={{ once: true }}
										whileInView={{ opacity: 1, y: '0%' }}
										transition={{
											duration: '.5',
											delay: 1.3,
										}}
									>
										<a
											target='_blank'
											href={'https://www.snapchat.com/add/surahxsurah'}
											className=' hover:text-[#ffae00] fill-current transition-all uration-300'
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 16 16'
												className='fill-current w-7 lg:w-10'
												id='snapchat'
											>
												<path d='M15.686 11.201c-2.139-.352-3.103-2.543-3.142-2.636l-.009-.018c-.109-.222-.135-.405-.075-.544.117-.274.565-.416.861-.51.086-.027.167-.053.234-.079.292-.115.5-.24.638-.381.164-.169.196-.331.194-.436-.005-.256-.201-.484-.514-.595a.93.93 0 0 0-.348-.066.775.775 0 0 0-.324.066c-.25.117-.474.181-.667.19a.612.612 0 0 1-.221-.048l.019-.309.003-.046c.069-1.083.154-2.43-.204-3.23a4.345 4.345 0 0 0-.979-1.401A4.094 4.094 0 0 0 9.981.386 4.627 4.627 0 0 0 8.154 0h-.04l-.275.003c-.355 0-1.066.05-1.829.386a4.071 4.071 0 0 0-1.167.771 4.359 4.359 0 0 0-.975 1.398c-.358.799-.273 2.146-.205 3.228v.002l.022.355a.64.64 0 0 1-.262.049c-.202 0-.442-.064-.713-.191a.64.64 0 0 0-.271-.056.958.958 0 0 0-.479.134c-.186.109-.306.262-.339.433-.021.112-.02.335.228.561.137.124.337.239.596.341.068.027.149.052.234.079.296.094.745.236.862.51.059.139.033.322-.076.544l-.008.018a5.295 5.295 0 0 1-.806 1.246 4.523 4.523 0 0 1-.972.858 3.577 3.577 0 0 1-1.365.531.373.373 0 0 0-.313.39c.003.055.016.11.039.163v.001c.077.179.254.33.542.463.352.163.878.3 1.565.407.035.066.071.231.096.344.026.12.053.244.092.375.042.142.15.312.429.312.106 0 .227-.024.368-.051.206-.04.488-.095.839-.095.195 0 .397.017.6.051.392.065.73.303 1.121.579.572.404 1.22.861 2.21.861l.081-.003c.032.002.073.003.115.003.99 0 1.638-.457 2.21-.86l.001-.001c.391-.275.729-.513 1.121-.578.203-.034.405-.051.6-.051.336 0 .602.043.839.089.155.03.275.045.368.045h.018a.405.405 0 0 0 .411-.307 5.3 5.3 0 0 0 .092-.372c.023-.106.061-.276.095-.342.687-.108 1.213-.244 1.565-.407.287-.133.464-.284.541-.462a.475.475 0 0 0 .04-.165.372.372 0 0 0-.313-.388z'></path>
											</svg>
										</a>
									</motion.li>
								</ul>
								<a
									href='http://www.developedbyabdu.com'
									target='_blank'
									rel='noopener noreferrer'
									className='text-sm lg:text-lg hover:text-[#ffae00] transition-all duration-300'
								>
									developedByAbdu.com
								</a>
							</div>
						</div>
					</section>
				</beta>
			</div>

			<section className='container mx-auto h-screen-s w-full p-5 flex items-center justify-between relative'>
				{/* &&&&&&&&&&&&&&&&&&&&&&&&& Beta Menu &&&&&&&&&&&&&&&&&&&&&&&&& */}

				<button
					onClick={() => {
						setIsBetaOn(true);
					}}
					className='beta absolute top-5 left-5 lg:left-40 bg-p-dark hover:bg-y-dark hover:text-p-dark transition-all py-1 px-2 rounded-full text-xs text-white'
				>
					تجريبي
				</button>

				{/* &&&&&&&&&&&&&&&&&&&&&&&&& Beta Menu &&&&&&&&&&&&&&&&&&&&&&&&& */}
				<div
					onClick={() => {
						setIsRecitersMenuOpened(!isRecitersMenuOpened);
					}}
					className='reciter-icon absolute top-5 right-5 lg:right-40 rounded-3xl flex items-center justify-center transition-all opacity-80 hover:opacity-100 cursor-pointer  z-[98]'
				>
					<svg
						className='w-8  p-1 transition-all fill-current text-p-dark'
						xmlns='http://www.w3.org/2000/svg'
						enable-background='new 0 0 512 512'
						viewBox='0 0 512 512'
						id='muslim'
					>
						<path
							d='M335.406,324.748c22.476-28.227,33.029-66.374,31.34-113.965c11.977-2.532,20.999-13.085,20.999-25.748
					c0-9.761-5.434-18.203-13.348-22.74c1.741-2.532,2.744-5.646,2.744-8.917c0-0.792,0-50.967,0-50.967
					c0-56.191-45.692-101.883-101.83-101.883h-38.727c-56.138,0-101.83,45.692-101.83,101.883v50.967
					c0,3.324,1.055,6.384,2.796,8.917c-7.914,4.59-13.296,12.979-13.296,22.74c0,12.663,9.022,23.215,20.946,25.748
					c-1.636,47.591,8.864,85.737,31.34,113.965h-4.116c-83.047,0-150.634,67.587-150.634,150.634
					c0,19.891,16.198,36.089,36.089,36.089h396.239c19.891,0,36.089-16.198,36.089-36.089
					C490.208,396.873,427.844,324.801,335.406,324.748z M280.956,293.83c0,8.442-11.449,15.565-24.956,15.565
					c-13.56,0-25.009-7.123-25.009-15.565S242.44,278.265,256,278.265C269.507,278.265,280.956,285.388,280.956,293.83z
					 M145.306,102.411c0-50.388,40.943-91.331,91.277-91.331h38.727c50.335,0,91.278,40.943,91.278,91.331v21.21
					c-77.296-21.896-151.637-20.524-221.282-0.105V102.411z M150.635,200.811c-8.706,0-15.828-7.07-15.828-15.776
					c0-8.864,6.964-15.828,15.828-15.828c0.052,0,0.052-0.053,0.105-0.053c8.653-0.053,15.618-7.123,15.618-15.776v-24.534
					c58.618-14.14,118.713-14.034,179.178,0.264v24.27c0,8.706,7.07,15.776,15.828,15.828c4.432,0,8.389,1.741,11.238,4.59
					c2.849,2.849,4.59,6.806,4.59,11.238c0,8.706-7.123,15.776-15.828,15.776c-1.741,0-3.746,1.108-4.696,2.849
					c-10.236,19.786-21.527,36.511-33.609,49.754c-6.279,6.859-16.989,7.914-23.637,1.688c-22.687-21.368-64.105-21.368-86.845,0
					c-6.859,6.437-17.57,4.96-23.637-1.688c-12.029-13.19-23.373-29.969-33.609-49.754
					C154.434,201.972,152.482,200.811,150.635,200.811z M185.88,335.301c10.236,10.289,22.424,18.994,36.511,26.064
					c20.735,10.394,45.797,10.764,67.165,0c14.088-7.07,26.275-15.776,36.511-26.064c18.414,0,14.298,0,17.095,0.053
					c-3.06,36.986-40.943,66.321-87.162,66.321s-84.102-29.335-87.162-66.268C175.697,335.195,174.22,335.353,185.88,335.301z
					 M454.119,500.919H57.881c-14.087,0-25.537-11.449-25.537-25.537c0-72.494,55.347-132.273,125.942-139.396
					c3.43,42.526,45.903,76.24,97.714,76.24s94.232-33.715,97.714-76.135c70.384,7.123,125.942,67.429,125.942,139.29
					C479.656,489.47,468.207,500.919,454.119,500.919z'
						></path>
					</svg>
					<span className='text-xs hidden md:block'>
						{currReciter?.filter((r) => r.identifier === reciter)[0]?.name ||
							'اختر القارئ'}
					</span>
				</div>
				<RecitersMenu />
				{/* <TafsirMenu /> */}

				{/* <Aside /> */}

				<section className='outlet w-full h-full flex flex-col justify-start items-center'>
					<Link
						to={'/'}
						className='text-center text-3xl mb-5 font-bold  hover:text-y-dark transition-all'
					>
						سورة x صورة
					</Link>
					<CurrSurah />
					<AyatNav />
					<div className='outlet flex-1 w-full lg:w-5/6'>
						<Outlet />
					</div>
				</section>
			</section>
		</>
	);
};
