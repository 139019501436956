import { useAppContext } from "../../context/store";

export const RecitersMenu = () => {
  const {
    currReciter,
    isRecitersMenuOpened,
    setIsRecitersMenuOpened,
    setIsAudioPlaying,
    setSearchParams,
    setIsAsideOpened,
    reciter,
    setAyahTimesPlayed,
    setBtnLoader,
  } = useAppContext();

  const handleChangeReciter = (r) => {
    setSearchParams((prev) => {
      prev.set("reciter", r.identifier);
      return prev;
    });
    setAyahTimesPlayed(1);
    setIsRecitersMenuOpened(false);
    setIsAudioPlaying(false);
    setIsAsideOpened(false);
    setBtnLoader(true);
    setTimeout(() => {
			setBtnLoader(false);
			console.log('settimeout');
		}, 100);
  };
  return (
    <div
      id="recitersMenuOverlay"
      onClick={(e) => {
        if (e.target.id === "recitersMenuOverlay") {
          setIsRecitersMenuOpened(false);
        }
      }}
      className={`${
        isRecitersMenuOpened ? "" : "scale-0 opacity-0"
      } transition-all absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center text-center z-[101] `}
    >
      <section className="relative reciters-menu bg-p-dark text-p-lighter w-72 h-96 rounded-xl p-2">
        <svg
          onClick={() => {
            setIsRecitersMenuOpened(false);
          }}
          className="absolute w-8 top-3 right-3 cursor-pointer hover:bg-white/20 transition-all rounded-full p-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
          id="x"
        >
          <line
            x1="200"
            x2="56"
            y1="56"
            y2="200"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          ></line>
          <line
            x1="200"
            x2="56"
            y1="200"
            y2="56"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          ></line>
        </svg>
        <h2 className="font-bold text-2xl py-5">قائمة القراء</h2>
        <div className="overflow-y-auto no-scrollbar h-72 ">
          <menu className="flex items-center justify-center overflow-y-auto no-scrollbar flex-col  gap-1 p-2 rounded-3xl">
            {currReciter?.map((r) => (
              <button
                onClick={() => {
									handleChangeReciter(r);
									
								}}
                key={r.identifier}
                className={`${
                  reciter === r.identifier ? "bg-y-dark" : ""
                } p-2 hover:bg-y-dark rounded-3xl w-full`}
              >
                {r.name}
              </button>
            ))}
          </menu>
        </div>
      </section>
    </div>
  );
};
